// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-docs-card-tsx": () => import("./../../../src/pages/docs/card.tsx" /* webpackChunkName: "component---src-pages-docs-card-tsx" */),
  "component---src-pages-docs-cardcheck-tsx": () => import("./../../../src/pages/docs/cardcheck.tsx" /* webpackChunkName: "component---src-pages-docs-cardcheck-tsx" */),
  "component---src-pages-docs-cardlist-tsx": () => import("./../../../src/pages/docs/cardlist.tsx" /* webpackChunkName: "component---src-pages-docs-cardlist-tsx" */),
  "component---src-pages-docs-gettingstarted-tsx": () => import("./../../../src/pages/docs/gettingstarted.tsx" /* webpackChunkName: "component---src-pages-docs-gettingstarted-tsx" */),
  "component---src-pages-docs-icons-tsx": () => import("./../../../src/pages/docs/icons.tsx" /* webpackChunkName: "component---src-pages-docs-icons-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-input-tsx": () => import("./../../../src/pages/docs/input.tsx" /* webpackChunkName: "component---src-pages-docs-input-tsx" */),
  "component---src-pages-docs-swipe-tsx": () => import("./../../../src/pages/docs/swipe.tsx" /* webpackChunkName: "component---src-pages-docs-swipe-tsx" */),
  "component---src-pages-docs-switch-tsx": () => import("./../../../src/pages/docs/switch.tsx" /* webpackChunkName: "component---src-pages-docs-switch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

